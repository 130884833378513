import React, {useEffect, useState} from 'react';
import CardComponent from './CardComponent.js';
import {getRequest} from "../../Fetch/request";
import Loader from "../Loader/Loader";
import {Tab, Tabs} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import RoadMap from "./RoadMap";
import GenerateAssignment from "./GenerateAssignment";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const Application = () => {


    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const [applications, setApplications] = useState([
    ]);

    const [level1Applications, setLevel1Applications] = useState([]);

    const handleClickForLevel2 = (githubLink) => {
        // Save title to local storag
        window.open("https://github.com/slazyslother/" + githubLink, '_blank');
    };

    const handleClickForLevel1 = (id) => {
        // Save title to local storage
        window.location.href = "/subApplications?applicationId=" + id;
    };

    useEffect(() => {
        const runParallelTasks = async () => {
            try {
                await Promise.all([ fetchAllApplication(), fetchAllLevel1Application()]);
            } catch (error) {
                console.error("Error while performing parallel tasks:", error);
            }
        };

        runParallelTasks();
    }, []);

    const fetchAllApplication = async () => {
        let getResponse = await getRequest(process.env.REACT_APP_URL + "/assignment/getApplications");
        if( getResponse.status === "200" && getResponse["response"] != null ){
            setApplications(getResponse["response"]);
        }
    };

    const fetchAllLevel1Application = async () => {
        let getResponse = await getRequest(process.env.REACT_APP_URL + "/assignment/getLevel1Applications");
        if( getResponse.status === "200" && getResponse["response"] != null ){
            setLevel1Applications(getResponse["response"]);
        }
    };

    return (
        <Container>
            <Box sx={{ width: '100%'}}>
                <Tabs value={value} onChange={handleChange} centered>
                    <Tab label="Level 1" />
                    <Tab label="Level 2" />
                    <Tab label="Level 3" />
                </Tabs>
                <TabPanel value={value} index={0} >
                    <RoadMap/>
                </TabPanel>
                <TabPanel value={value} index={1} >
                        {(
                            (level1Applications.length > 0) ?
                                (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            justifyContent: "space-between",
                                            alignItems: "stretch",
                                            height: "100%",
                                            gap: '20px'
                                        }}>
                                        {level1Applications.map(level1Application => <CardComponent id={level1Application.id} githubLink={level1Application.githubLink}
                                                       title={level1Application.title}
                                                       handleClick={handleClickForLevel2}/>
                                        )}

                                    </div>)
                                        :
                                        (<Loader/>)
                                        )}
                            </TabPanel>
                <TabPanel value={value} index={2}>
                    {(
                        <div                                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                            alignItems: "stretch",
                            height: "100%",
                            gap: '20px'
                        }}>
                            <GenerateAssignment/>
                            {/*<h2>*/}
                            {/*    Level2 Applications <br/> Coming Soon*/}
                            {/*</h2>*/}
                        </div>
                        // (applications.length > 0) ?
                        //     (
                        //         <div
                        //             style={{
                        //                 display: "flex",
                        //                 flexWrap: "wrap",
                        //                 justifyContent: "space-between",
                        //                 alignItems: "stretch",
                        //                 height: "100%"
                        //             }}>
                        //             {applications.map(application=> <CardComponent id={application.id} handleClick={handleClickForLevel1}/>)}
                        //         </div>)
                        //     :
                        //     (<Loader/>)
                    )}
                            </TabPanel>
            </Box>
        </Container>
    );
};

export default Application;
