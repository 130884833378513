import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Card, CardContent, Container, Typography, Grid } from '@mui/material';

// Array of session details
const sessions = [
    {
        sessionId: 1,
        title: 'Session 1: Arrays and Strings',
        topics: ['Array manipulation', 'Two-pointer techniques', 'Sliding window problems', 'String manipulation'],
        totalQuestions: 15,
    },
    {
        sessionId: 2,
        title: 'Session 2: Linked Lists',
        topics: ['Singly linked lists', 'Doubly linked lists', 'Cycle detection', 'Reversal techniques'],
        totalQuestions: 12,
    },
    {
        sessionId: 3,
        title: 'Session 3: Trees',
        topics: ['Binary trees', 'Binary search trees', 'Tree traversal', 'Balanced trees'],
        totalQuestions: 18,
    },
    {
        sessionId: 4,
        title: 'Session 4: Graphs',
        topics: ['DFS & BFS', 'Graph traversal', 'Cycle detection', 'Topological sorting'],
        totalQuestions: 20,
    },
    {
        sessionId: 5,
        title: 'Session 5: Dynamic Programming',
        topics: ['Memoization', 'Tabulation', 'Optimal substructure', 'Overlapping subproblems'],
        totalQuestions: 25,
    },
    {
        sessionId: 6,
        title: 'Session 6: Sorting Algorithms',
        topics: ['Merge Sort', 'Quick Sort', 'Heap Sort', 'Bubble Sort'],
        totalQuestions: 10,
    },
    {
        sessionId: 7,
        title: 'Session 7: Searching Algorithms',
        topics: ['Binary Search', 'Ternary Search', 'Exponential Search'],
        totalQuestions: 13,
    },
];

// Function to render sessions in reverse order (latest first)
const SessionDetails = () => {
    const navigate = useNavigate();

    const renderSessions = () => {
        // Reverse the session array to start with the latest session
        const reversedSessions = [...sessions].reverse();

        return reversedSessions.map((session) => (
            <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                key={session.sessionId}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '10px',
                }}
            >
                <Card
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        textAlign: 'center',
                        padding: '20px',
                        borderRadius: '15px',
                        position: 'relative',
                        transition: 'transform 0.3s ease-in-out',
                        backgroundColor: session.sessionId === sessions.length ? '#66B2B2' : '#fff', // Lighter background color for visibility
                        color: session.sessionId === sessions.length ? '#fff' : '#333',
                        boxShadow: session.sessionId === sessions.length ? '0px 4px 15px rgba(0, 0, 0, 0.2)' : 'none',
                        '&:hover': {
                            transform: 'scale(1.05)',
                            backgroundColor: session.sessionId === sessions.length ? '#5A9D9D' : '#f9f9f9', // Hover effect for the latest session
                        },
                    }}
                >
                    {/* Top-Left Session No */}
                    {(
                        <Typography
                            variant="h5"
                            sx={{ position: 'absolute', top: '10px', left: '10px', fontWeight: 'bold' ,
                                color: session.sessionId === sessions.length ? '#fff' : '#333'
                            }}
                        >
                            Session {session.sessionId}
                        </Typography>
                    )}

                    {/* Top-Right Total Questions */}
                    <Typography
                        variant="body2"
                        sx={{ position: 'absolute', top: '10px', right: '10px', fontWeight: 'bold' }}
                    >
                        {session.totalQuestions} Questions
                    </Typography>

                    {/* Centered Topics Covered, spread horizontally */}
                    <CardContent
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexGrow: 1,
                            marginTop: '20px'
                        }}
                    >
                        <Typography
                            variant="body1"
                            sx={{
                                textAlign: 'center',
                                fontWeight: 'bold',
                                width: '100%',
                            }}
                        >
                            {session.topics.join(' | ')}
                        </Typography>
                    </CardContent>

                    {/* Bottom Buttons with gap from edges */}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            paddingX: '10px',
                            paddingBottom: '10px',
                            gap: '20px', // Increased gap between buttons
                        }}
                    >
                        {/* Session Summary Button */}
                        <Button
                            variant="outlined"
                            size="small"
                            sx={{
                                borderColor: '#008080',
                                color: session.sessionId === sessions.length ? '#fff' : '#008080',
                                borderRadius: '20px',
                                padding: '5px 20px', // Increased padding for buttons
                                '&:hover': {
                                    backgroundColor: '#008080',
                                    borderColor: '#008080',
                                    color: '#fff',
                                },
                            }}
                            onClick={() => navigate('/session-performance')}
                        >
                            Summary
                        </Button>

                        {/* Start Session Button */}
                        <Button
                            variant="outlined"
                            size="small"
                            sx={{
                                borderColor: '#008080',
                                color: session.sessionId === sessions.length ? '#fff' : '#008080',
                                borderRadius: '20px',
                                padding: '5px 20px', // Increased padding for buttons
                                '&:hover': {
                                    backgroundColor: '#008080',
                                    borderColor: '#008080',
                                    color: '#fff',
                                },
                            }}
                            onClick={() => navigate('/problem-details')}
                        >
                            Start
                        </Button>
                    </Box>
                </Card>
            </Grid>
        ));
    };

    return (
        <Container
            maxWidth="xl"
            sx={{
                marginTop: '20px',
                padding: '20px',
            }}
        >
            <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
                {renderSessions()}
            </Grid>
        </Container>
    );
};

export default SessionDetails;
