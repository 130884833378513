import React, { useEffect, useState } from 'react';
import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';
import './Heatmap.css';
import { Container, Tooltip } from '@mui/material';

const Heatmap = ({ submissionCounts = [2, 5] }) => {
    const [heatmapData, setHeatmapData] = useState([]);
    const today = new Date();

    useEffect(() => {
        const transformedData = submissionCounts.map((count, index) => {
            if (count === 0) return null;
            
            const date = new Date();
            date.setDate(today.getDate() - (submissionCounts.length - 1 - index));
            
            return {
                date: date.toISOString().split('T')[0],
                count: count
            };
        }).filter(item => item !== null);

        setHeatmapData(transformedData);
    }, [submissionCounts]);

    const CustomTooltip = ({ date, count }) => (
        <div style={{ padding: '8px' }}>
            <div>Date: {date}</div>
            <div>Submissions: {count}</div>
        </div>
    );

    return (
        <Container sx={{ marginTop: '20px' }}>
            <CalendarHeatmap
                startDate={new Date(today.getFullYear(), today.getMonth() - 11, 1)}
                endDate={today}
                values={heatmapData}
                classForValue={(value) => {
                    if (!value) {
                        return 'color-empty';
                    }
                    if (value.count <= 2) return 'color-scale-1';
                    if (value.count <= 4) return 'color-scale-2';
                    if (value.count <= 6) return 'color-scale-3';
                    return 'color-scale-4';
                }}
                titleForValue={(value) => {
                    if (!value) return 'No submissions';
                    return `${value.date}: ${value.count} submissions`;
                }}
                showWeekdayLabels={true}
            />
        </Container>
    );
};

export default Heatmap;
