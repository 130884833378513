import React, { useState } from 'react';
import { Box, Button, Card, Container, Typography, Grid, Chip, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import './QuizApp.css';
import {getRequest} from "../../Fetch/request";
import Loader from '../Loader/Loader';

// Example list of topics and tags
const topicsList = ['Operating System', 'Database Management System', 'Networking', 'Object Oriented Programming', 'System Design'];
const topicTags = {
    'Operating System': ['Process Management', 'Memory Management', 'File Systems', 'CPU Scheduling', 'Deadlocks'],
    'Database Management System': ['SQL', 'Normalization', 'Transactions', 'Indexing', 'ACID Properties'],
    'Networking': ['TCP/IP', 'OSI Model', 'Protocols', 'Network Security', 'Routing'],
    'Object Oriented Programming': ['Inheritance', 'Polymorphism', 'Encapsulation', 'Classes', 'Interfaces'],
    'System Design': ['Scalability', 'Load Balancing', 'Caching', 'Microservices', 'Database Design']
};

const QuizApp = () => {
    const [selectedFormat, setSelectedFormat] = useState('');
    const [selectedTopic, setSelectedTopic] = useState('');
    const [tags, setTags] = useState([]);
    const [quizStarted, setQuizStarted] = useState(false);
    const [flashcardStarted, setFlashcardStarted] = useState(false);
    const [answers, setAnswers] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [currentFlashcardIndex, setCurrentFlashcardIndex] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const [quizQuestions, setQuizQuestions] = useState([
        { question: "What is the time complexity of Merge Sort?", options: ["O(n^2)", "O(log n)", "O(n log n)", "O(n)"], correctAnswer: "O(n log n)" },
        { question: "Which data structure is used for BFS?", options: ["Stack", "Queue", "Heap", "Graph"], correctAnswer: "Queue" },
    ]);

    const [flashCards, setFlashCards] = useState([
        { question: "What is a binary search?", answer: "Binary search is a divide and conquer algorithm with a time complexity of O(log n)." },
        { question: "What is memoization?", answer: "Memoization is an optimization technique used to store the results of expensive function calls." },
    ]);

    // Handle starting the test
    const handleStartTest = async () => {
        setIsLoading(true); // Start loading
        try {
            const tagsQuery = tags.length > 0 ? `&tags=${tags.join(',')}` : '&tags=';
            let getResponse = await getRequest(`${process.env.REACT_APP_URL_AI}/${selectedFormat}?topic=${selectedTopic}${tagsQuery}`);

            if (selectedFormat === "flashcard") {
                setFlashCards(getResponse);
                setFlashcardStarted(true);
            } else {
                const formattedQuestions = getResponse.map((item) => {
                    const correctIndex = item.correct_option.charCodeAt(0) - 'a'.charCodeAt(0);
                    return {
                        question: item.question,
                        options: item.options,
                        correctAnswer: item.options[correctIndex]
                    };
                });
                setQuizQuestions(formattedQuestions);
                setQuizStarted(true);
            }
        } catch (error) {
            console.error("Error fetching questions:", error);
        } finally {
            setIsLoading(false); // End loading
        }
    };

    // Handle answer selection
    const handleAnswerSelect = (questionIndex, option) => {
        setSelectedAnswers({ ...selectedAnswers, [questionIndex]: option });
    };

    // Handle quiz submission
    const handleSubmitQuiz = () => {
        setSubmitted(true);
    };

    // Handle flashcard navigation with animation
    const handleNextFlashcard = () => {
        setIsAnimating(true);
        setTimeout(() => {
            setCurrentFlashcardIndex((prevIndex) => (prevIndex + 1) % flashCards.length);
            setIsAnimating(false);
        }, 300);
    };

    const handlePrevFlashcard = () => {
        setIsAnimating(true);
        setTimeout(() => {
            setCurrentFlashcardIndex((prevIndex) => (prevIndex === 0 ? flashCards.length - 1 : prevIndex - 1));
            setIsAnimating(false);
        }, 300);
    };

    // Update tag options when topic changes
    const handleTopicChange = (event) => {
        const newTopic = event.target.value;
        setSelectedTopic(newTopic);
        setTags([]); // Reset tags when topic changes
    };

    // Render the quiz
    const renderQuiz = () => (
        <Grid container spacing={3}>
            {quizQuestions.map((quiz, questionIndex) => (
                <Grid item xs={12} key={questionIndex}>
                    <Card sx={{ padding: '20px', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)' }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '15px' }}>{quiz.question}</Typography>
                        <Grid container spacing={2}>
                            {quiz.options.map((option, i) => (
                                <Grid item xs={12} sm={6} key={i}>
                                    <Button
                                        variant="outlined"
                                        fullWidth
                                        onClick={() => handleAnswerSelect(questionIndex, option)}
                                        sx={{
                                            borderColor:
                                                submitted && option === quiz.correctAnswer
                                                    ? 'green'
                                                    : submitted && selectedAnswers[questionIndex] === option
                                                        ? 'red'
                                                        : selectedAnswers[questionIndex] === option
                                                            ? '#008080'
                                                            : '#ccc',
                                            color:
                                                submitted && option === quiz.correctAnswer
                                                    ? 'green'
                                                    : submitted && selectedAnswers[questionIndex] === option
                                                        ? 'red'
                                                        : selectedAnswers[questionIndex] === option
                                                            ? '#fff'
                                                            : '#000',
                                            backgroundColor:
                                                selectedAnswers[questionIndex] === option && !submitted
                                                    ? '#008080'
                                                    : submitted && option === quiz.correctAnswer
                                                        ? 'rgba(0, 128, 0, 0.1)'
                                                        : 'transparent',
                                            '&:hover': {
                                                backgroundColor:
                                                    submitted && option === quiz.correctAnswer
                                                        ? 'rgba(0, 128, 0, 0.1)'
                                                        : selectedAnswers[questionIndex] === option
                                                            ? '#008080'
                                                            : 'transparent',
                                                color:
                                                    selectedAnswers[questionIndex] === option || option === quiz.correctAnswer
                                                        ? '#fff'
                                                        : '#000',
                                            },
                                        }}
                                    >
                                        {option}
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                    </Card>
                </Grid>
            ))}
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    sx={{ backgroundColor: '#008080', color: '#fff', padding: '12px 30px', borderRadius: '10px', '&:hover': { backgroundColor: '#006666' } }}
                    onClick={handleSubmitQuiz}
                    disabled={submitted}
                >
                    Submit Quiz
                </Button>
            </Grid>
            {submitted && (
                <Grid item xs={12}>
                    <Typography variant="h6" sx={{ marginTop: '20px', fontWeight: 'bold', color: '#008080' }}>
                        Quiz Completed! You answered {Object.keys(selectedAnswers).filter((index) => quizQuestions[index].correctAnswer === selectedAnswers[index]).length} out of {quizQuestions.length} questions correctly.
                    </Typography>
                </Grid>
            )}
        </Grid>
    );

    // Render flashcards with flip effect and navigation
    const renderFlashcards = () => (
        <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} sm={6} md={6}>
                <div className={`flip-card ${isAnimating ? 'fade-out' : 'fade-in'}`}>
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <span
                                className="card-number">Card {currentFlashcardIndex
                                + 1}/{flashCards.length}</span>
                            <div className="card-content">
                                <Typography variant="h6" sx={{
                                    fontWeight: 'bold',
                                    marginBottom: '10px'
                                }}>
                                    {flashCards[currentFlashcardIndex].question}
                                </Typography>
                            </div>
                            <span
                                className="card-hint">Hover to reveal answer</span>
                        </div>
                        <div className="flip-card-back">
                            <div className="card-content">
                                <Typography variant="h6" sx={{
                                    fontWeight: 'bold',
                                    marginBottom: '10px',
                                }}>
                                    {flashCards[currentFlashcardIndex].answer}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Navigation for flashcards */}
                <div className="card-navigation">
                    <Button onClick={handlePrevFlashcard}>
                        <ArrowBackIosIcon/>
                    </Button>
                    <Button onClick={handleNextFlashcard}>
                        <ArrowForwardIosIcon />
                    </Button>
                </div>
            </Grid>
        </Grid>
    );

    return (
        <Container
            maxWidth="md"
            sx={{
                marginTop: '30px',
                padding: '30px',
                // backgroundColor: '#f9f9f9',
                // borderRadius: '15px',
                boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
            }}
        >
            { isLoading ? (
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '400px' // Prevent layout shift
                        }}>
                            <Loader />
                        </Box>
                    ) : (
                    <>
            {!quizStarted && !flashcardStarted ? (
                <Box>
                    <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#008080', marginBottom: '20px', textAlign: 'center' }}>
                        Create Your Custom Test
                    </Typography>

                    {/* Topic Input */}
                    <FormControl fullWidth sx={{ marginBottom: '20px' }}>
                        <InputLabel id="select-topic-label">Choose Topic</InputLabel>
                        <Select
                            labelId="select-topic-label"
                            value={selectedTopic}
                            onChange={handleTopicChange}
                            label="Choose Topic"
                            sx={{ backgroundColor: '#fff' }}
                        >
                            {topicsList.map((topic) => (
                                <MenuItem key={topic} value={topic}>
                                    {topic}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {/* Tag Input */}
                    <FormControl fullWidth sx={{ marginBottom: '20px' }}>
                        <InputLabel id="select-tags-label">Tags</InputLabel>
                        <Select
                            labelId="select-tags-label"
                            multiple
                            value={tags}
                            onChange={(e) => setTags(e.target.value)}
                            label="Tags"
                            sx={{ backgroundColor: '#fff' }}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} sx={{ backgroundColor: '#008080', color: '#fff' }} />
                                    ))}
                                </Box>
                            )}
                        >
                            {selectedTopic && topicTags[selectedTopic].map((tag) => (
                                <MenuItem key={tag} value={tag}>
                                    {tag}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {/* Format Selector */}
                    <FormControl fullWidth sx={{ marginBottom: '30px' }}>
                        <InputLabel id="select-format-label">Select Format</InputLabel>
                        <Select
                            labelId="select-format-label"
                            value={selectedFormat}
                            onChange={(e) => setSelectedFormat(e.target.value)}
                            label="Select Format"  // Ensure the label is linked
                            sx={{ backgroundColor: '#fff' }}
                        >
                            <MenuItem value="quiz">Quiz</MenuItem>
                            <MenuItem value="flashcard">Flashcards</MenuItem>
                        </Select>
                    </FormControl>

                    {/* Start Test Button */}
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#008080',
                            color: '#fff',
                            fontWeight: 'bold',
                            padding: '12px 30px',
                            borderRadius: '10px',
                            '&:hover': { backgroundColor: '#006666' },
                            display: 'block',
                            margin: 'auto',
                        }}
                        onClick={handleStartTest}
                        disabled={!selectedTopic || !selectedFormat}
                    >
                        Start Test
                    </Button>
                </Box>
            ) : selectedFormat === 'quiz' ? (
                <Box>
                    <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333', textAlign: 'center' }}>
                        Quiz: {selectedTopic}
                    </Typography>
                    <br/>
                    {renderQuiz()}
                </Box>
            ) : (
                <Box>
                    <Typography variant="h5" sx={{fontWeight: 'bold', color: '#333', textAlign: 'center'}}>
                        Flashcards: {selectedTopic}
                    </Typography>
                    <br/>
                    {renderFlashcards()}
                </Box>
            )
        }</>)}
        </Container>
    );
};

export default QuizApp;
