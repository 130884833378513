import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Card, CardContent, Container, Typography, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

// Example data for the session performance
const performanceData = {
    totalCorrect: 10,
    totalWrong: 3,
    totalAttempt: 2,
    averageTimeTakenPerQuestion: 2,
    topics: [
        { topic: 'Arrays', beforeRating: 3, newRating: 4, message: 'Good Improvement' },
        { topic: 'Linked Lists', beforeRating: 2, newRating: 2, message: 'Needs Improvement' },
        { topic: 'Dynamic Programming', beforeRating: 1, newRating: 3, message: 'Improved' },
        { topic: 'Trees', beforeRating: 3, newRating: 4, message: 'Consistent Performance' },
    ],
};

const SessionPerformance = () => {
    const navigate = useNavigate();

    return (
        <Container
            maxWidth="md"
            sx={{
                marginTop: '50px',
                backgroundColor: '#f9f9f9',
                padding: '30px',
                borderRadius: '20px',
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
            }}
        >
            <Box textAlign="center" mb={4}>
                <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#008080' }}>
                    Session Performance Dashboard
                </Typography>

                {/* Performance Summary */}
                <Grid container spacing={4} justifyContent="center">
                    <Grid item xs={12} sm={3}>
                        <Card
                            sx={{
                                background: 'linear-gradient(135deg, #8bc34a, #4caf50)',
                                color: 'white',
                                boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
                                '&:hover': {
                                    boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.2)',
                                },
                            }}
                        >
                            <CardContent>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    Questions Solved
                                </Typography>
                                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                                    {performanceData.totalCorrect}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Card
                            sx={{
                                background: 'linear-gradient(135deg, #e91e63, #f06292)',
                                color: 'white',
                                boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
                                '&:hover': {
                                    boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.2)',
                                },
                            }}
                        >
                            <CardContent>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    Questions Incorrect
                                </Typography>
                                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                                    {performanceData.totalWrong}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Card
                            sx={{
                                background: 'linear-gradient(135deg, #ff9800, #ffb74d)',
                                color: 'white',
                                boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
                                '&:hover': {
                                    boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.2)',
                                },
                            }}
                        >
                            <CardContent>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    Questions Skipped
                                </Typography>
                                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                                    {performanceData.totalAttempt}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Card
                            sx={{
                                background: 'linear-gradient(135deg, #03a9f4, #4fc3f7)',
                                color: 'white',
                                boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
                                '&:hover': {
                                    boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.2)',
                                },
                            }}
                        >
                            <CardContent>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    Avg. Time Taken (mins)
                                </Typography>
                                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                                    {performanceData.averageTimeTakenPerQuestion}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>

            {/* Performance Table Per Topic */}
            <Box mt={4}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#008080' }}>
                    Topic-wise Performance
                </Typography>
                <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
                    <Table aria-label="topic-wise performance table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" sx={{ fontWeight: 'bold', color: '#008080' }}>
                                    Topic
                                </TableCell>
                                <TableCell align="center" sx={{ fontWeight: 'bold', color: '#008080' }}>
                                    Before Rating
                                </TableCell>
                                <TableCell align="center" sx={{ fontWeight: 'bold', color: '#008080' }}>
                                    New Rating
                                </TableCell>
                                <TableCell align="center" sx={{ fontWeight: 'bold', color: '#008080' }}>
                                    Message
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {performanceData.topics.map((topic, index) => (
                                <TableRow
                                    key={index}
                                    sx={{
                                        '&:nth-of-type(odd)': { backgroundColor: '#f3f3f3' },
                                        '&:hover': {
                                            backgroundColor: '#e0f7fa',
                                        },
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        {topic.topic}
                                    </TableCell>
                                    <TableCell align="center">{topic.beforeRating}</TableCell>
                                    <TableCell align="center">{topic.newRating}</TableCell>
                                    <TableCell align="center">{topic.message}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            {/* Button to navigate to overall user performance */}
            <Box mt={4} textAlign="center">
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: '#008080',
                        '&:hover': { backgroundColor: '#006666' },
                    }}
                    onClick={() => navigate('/overall-performance')}
                >
                    View Overall Performance
                </Button>
            </Box>
        </Container>
    );
};

export default SessionPerformance;
