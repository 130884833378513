import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, Container, Typography, Grid, IconButton } from '@mui/material';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip,
    Legend,
} from 'chart.js';
import CloseIcon from '@mui/icons-material/Close';
import Heatmap from "../HeatMap/Heatmap";
import {getRequest} from "../../Fetch/request";

// Register Chart.js components
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

// Helper function to generate dates for the last year
const generateDates = (days) => {
    const today = new Date();
    const dates = [];
    for (let i = 0; i < days; i++) {
        const date = new Date(today);
        date.setDate(today.getDate() - i);
        dates.unshift(date.toISOString().split('T')[0]); // Format: YYYY-MM-DD
    }
    return dates;
};

// Example data for overall performance
const sampleOverallPerformanceData = {
    totalCorrect: 0,
    totalWrong: 0,
    totalAttempt: 0,
    accuracyRate: 0, // as percentage
    averageTimeTaken: 0, // in minutes
    totalSessions: 0,
    dailySubmissionCounts: [],
    masteryRatings: [],
};



// Helper class to generate the graph data for each topic
class GraphData {
    constructor(topicId, masteryHistory) {
        this.topicId = topicId;
        this.masteryHistory = masteryHistory;
        this.days = generateDates(365);
    }

    getGraphData(visibleDays) {
        return {
            labels: this.days.slice(-visibleDays), // Display only visible days
            datasets: [
                {
                    label: `Mastery Level for ${this.topicId}`,
                    data: this.masteryHistory.slice(-visibleDays),
                    borderColor: 'green', // Default color for the line
                    segment: {
                        borderColor: (ctx) => {
                            const { p0, p1 } = ctx;
                            const startValue = p0.raw;
                            const endValue = p1.raw;
                            return endValue >= startValue ? 'green' : 'red'; // Green if increasing, red if decreasing
                        },
                    },
                    fill: false,
                    tension: 0.4,
                },
            ],
        };
    }
}

const OverallUserPerformance = () => {
    const navigate = useNavigate();

    const [overallPerformanceData, setOverallPerformanceData] = useState(sampleOverallPerformanceData);
    const [visibleDays, setVisibleDays] = useState(30); // Show 30 days by default
    const [expandedGraph, setExpandedGraph] = useState(null); // Track which graph is expanded

    // Handle expanding the chart to show data for the last year (365 days)
    const handleExpand = (index) => {
        setVisibleDays(365); // Show data for the last 365 days
        setExpandedGraph(index); // Set the expanded graph index
    };

    // Handle closing the expanded chart view
    const handleCloseExpand = () => {
        setVisibleDays(30); // Return to 30 days by default
        setExpandedGraph(null); // Close the expanded graph view
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const profile = JSON.parse(localStorage.getItem("profileObject"));
                const email = profile?.email; // Safely access email
                const url = `${process.env.REACT_APP_URL}/api/dsa-dashboard?email=${email}&type=DSA`;

                const getResponse = await getRequest(url);

                if (getResponse.status === "200" && getResponse.response != null) {
                    setOverallPerformanceData(getResponse.response);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData(); // Call the async function
    }, []);

    return (
        <Container
            maxWidth="xl"
            sx={{
                marginTop: '20px',
                padding: '30px',
                width: '100%',
                height: '100vh', // Ensure the container height adjusts as needed
                minHeight: '100vh', // Ensure it takes at least the viewport height
            }}
        >
            <Box sx={{display: 'flex', width: '100%'}}>
                {/* Left side: Vertical buttons showing performance stats as cards */}
                <Box
                    sx={{
                        width: '20%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '15px',
                        padding: '10px',
                        borderRadius: '10px',
                        overflowY: 'auto', // Allow the left side to scroll if needed
                    }}
                >
                    <Card sx={{
                        backgroundColor: '#8bc34a',
                        color: 'white',
                        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)'
                    }}>
                        <CardContent>
                            <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                                Total Solved
                            </Typography>
                            <Typography variant="h4" sx={{fontWeight: 'bold'}}>
                                {overallPerformanceData.totalCorrect}
                            </Typography>
                        </CardContent>
                    </Card>

                    <Card sx={{
                        backgroundColor: '#f06292',
                        color: 'white',
                        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)'
                    }}>
                        <CardContent>
                            <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                                Total Incorrect
                            </Typography>
                            <Typography variant="h4" sx={{fontWeight: 'bold'}}>
                                {overallPerformanceData.totalWrong}
                            </Typography>
                        </CardContent>
                    </Card>

                    <Card sx={{
                        backgroundColor: '#ffb74d',
                        color: 'white',
                        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)'
                    }}>
                        <CardContent>
                            <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                                Total Submissions
                            </Typography>
                            <Typography variant="h4" sx={{fontWeight: 'bold'}}>
                                {overallPerformanceData.totalAttempt}
                            </Typography>
                        </CardContent>
                    </Card>

                    <Card sx={{
                        backgroundColor: '#03a9f4',
                        color: 'white',
                        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)'
                    }}>
                        <CardContent>
                            <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                                Accuracy (%)
                            </Typography>
                            <Typography variant="h4" sx={{fontWeight: 'bold'}}>
                                {overallPerformanceData.accuracyRate}%
                            </Typography>
                        </CardContent>
                    </Card>

                    <Card sx={{
                        backgroundColor: '#4fc3f7',
                        color: 'white',
                        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)'
                    }}>
                        <CardContent>
                            <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                                Avg. Time For Correct Submission (mins)
                            </Typography>
                            <Typography variant="h4" sx={{fontWeight: 'bold'}}>
                                {overallPerformanceData.averageTimeTaken}
                            </Typography>
                        </CardContent>
                    </Card>

                    <Card sx={{
                        backgroundColor: '#ff7043',
                        color: 'white',
                        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)'
                    }}>
                        <CardContent>
                            <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                                Sessions
                            </Typography>
                            <Typography variant="h4" sx={{fontWeight: 'bold'}}>
                                {overallPerformanceData.totalSessions}
                            </Typography>
                        </CardContent>
                    </Card>
                </Box>

                {/* Right side: Heatmap and Graphs */}
                <Box
                    sx={{
                        flex: 1,
                        paddingLeft: '20px',
                        maxHeight: '100vh', // Ensure the right side doesn't overflow
                    }}
                >
                    {/* Heatmap Section */}
                    <Box
                        sx={{
                            backgroundColor: '#f9f9f9',
                            padding: '20px',
                            borderRadius: '10px',
                            marginBottom: '20px',
                            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <Typography variant="h5" gutterBottom
                                    sx={{fontWeight: 'bold', color: '#008080'}}>
                            Questions Solved Over Time
                        </Typography>
                        <Heatmap submissionCounts={overallPerformanceData.dailySubmissionCounts} />
                    </Box>

                    {/* Individual Mastery Graph for Each Topic */}
                    <Box
                        sx={{
                            backgroundColor: '#f9f9f9',
                            padding: '20px',
                            borderRadius: '10px',
                            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <Typography variant="h5" gutterBottom
                                    sx={{fontWeight: 'bold', color: '#008080'}}>
                            Topic-wise Mastery Level
                        </Typography>

                        <Grid container spacing={2}>
                            {overallPerformanceData.masteryRatings.map(
                                (topicData, index) => {
                                    const graphData = new GraphData(
                                        topicData.topicId,
                                        topicData.masteryHistory,
                                        topicData.days).getGraphData(
                                        visibleDays);

                                    // If the graph is expanded, show it fullscreen
                                    if (expandedGraph === index) {
                                        return (
                                            <Box key={index} sx={{
                                                position: 'fixed',
                                                top: 0,
                                                left: 0,
                                                width: '100vw',
                                                height: '100vh',
                                                zIndex: 1000,
                                                backgroundColor: 'white',
                                                padding: '20px'
                                            }}>
                                                <IconButton
                                                    onClick={handleCloseExpand}
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '10px',
                                                        right: '10px',
                                                        zIndex: 1001
                                                    }}>
                                                    <CloseIcon
                                                        fontSize="large"/>
                                                </IconButton>
                                                <Typography variant="h4"
                                                            gutterBottom sx={{
                                                    textAlign: 'center',
                                                    color: '#008080'
                                                }}>
                                                    {topicData.topicId}
                                                </Typography>
                                                <Line
                                                    data={graphData}
                                                    options={{
                                                        scales: {
                                                            y: {
                                                                beginAtZero: true,
                                                                max: 2000,
                                                                ticks: {
                                                                    callback: (value) => value.toFixed(
                                                                        1),
                                                                },
                                                            },
                                                        },
                                                    }}
                                                    height={500}
                                                    width={1000}
                                                />
                                            </Box>
                                        );
                                    }

                                    // Default view with 3 graphs per row
                                    return (
                                        <Grid item xs={12} sm={4} key={index}>
                                            <Box mb={2}
                                                 sx={{position: 'relative'}}>
                                                <IconButton
                                                    onClick={() => handleExpand(
                                                        index)}
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '10px',
                                                        right: '10px',
                                                        zIndex: 10
                                                    }} // Moved to top right
                                                >
                                                    <ZoomOutMapIcon
                                                        fontSize="small"/>
                                                </IconButton>
                                                <Typography variant="h6" sx={{
                                                    fontWeight: 'bold',
                                                    color: '#333',
                                                    marginBottom: '10px'
                                                }}>
                                                    {topicData.topicId}
                                                </Typography>
                                                <Line
                                                    data={graphData}
                                                    options={{
                                                        scales: {
                                                            y: {
                                                                beginAtZero: true,
                                                                max: 2000,
                                                                ticks: {
                                                                    callback: (value) => value.toFixed(
                                                                        1),
                                                                },
                                                            },
                                                        },
                                                    }}
                                                    height={100}
                                                    width={200}
                                                />
                                            </Box>
                                        </Grid>
                                    );
                                })}
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};

export default OverallUserPerformance;
