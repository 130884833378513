import * as React from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import backgroundImage from "../../../slazysloth.jpg";
import {getRequest, postRequest} from "../../../Fetch/request";
import { GoogleButton } from 'react-google-button';
import {auth} from '../../../firebase'

import {signInWithPopup, GoogleAuthProvider, signOut} from 'firebase/auth';

const provider = new GoogleAuthProvider();

const defaultTheme = createTheme();

export default function Login({setIsAuthenticated}) {


    const signInWithGoogle = () => {
        signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                // You can handle the signed-in user here, e.g., redirect to another page.
                onlogin(user.email);
            })
            .catch((error) => {
                // Handle errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                console.error(errorCode, errorMessage, email, credential);
            });
    };

    const onlogin = async (email) => {
        localStorage.setItem("email", email);
        setIsAuthenticated(true);
        const userObject = {
            name:"",
            email:email,
            avatar : null,
            designation:"",
            company:"",
            college:"",
            leetcode:"",
            cgpa:"",
            links:[
                {
                    "name":"github",
                    "tag":"personal",
                    "link":""
                },
                {
                    "name":"linkedin",
                    "tag":"personal",
                    "link":""
                },
                {
                    "name":"twitter",
                    "tag":"personal",
                    "link":""
                }
            ]
        };

        let postResponse = await postRequest(process.env.REACT_APP_URL + "/user/login", userObject);
        if (postResponse.status === "200") {
        }else{
            onLogout();
        }
    }

    const onLogout = ()=>{
        signOut(auth)
            .then(() => {
                // Sign-out successful.
                setIsAuthenticated(false);
                localStorage.setItem("email",null);
            })
            .catch((error) => {
                // An error happened.
                console.error('Error signing out:', error);
            });
    }



  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: "pink",
            backgroundSize: "80%",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square sx={{ display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent:"center"}}>
          <Box
            sx={{
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent:"center"
            }}
          >
            <Avatar sx={{ m: 2, backgroundColor: "white",width:"200px",height:"200px"}}  >
              <img
                src={"/images/logo_without_background.png"}
                alt="slazysloth"
                style={{ height: "180px", width: "180px" }}
              />
            </Avatar>
            <Box
              component="form"
              noValidate
              sx={{ mt: 1 }}
            >
                <GoogleButton onClick={signInWithGoogle} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
