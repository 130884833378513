import React, {useEffect, useState} from 'react';
import { Container, Grid, Box, Typography, Avatar, Button, IconButton } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import GitHubIcon from '@mui/icons-material/GitHub';
import {postRequest} from "../../Fetch/request";
import PopUp from "../Popup/PopUp";
import "./Profile.css"
import PopupForm from "./PopupForm";
import Loader from "../Loader/Loader";


const ProfilePage = () => {



    const [userProfile, setUserProfile] = useState({});
    const [uploading,setUploading] = useState(0);

    const handleChange = (e) => {
        setUserProfile({ ...userProfile, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        const fetchData = async () => {
            const profileData = JSON.parse(localStorage.getItem("profileObject"));
            if (profileData) {
                setUserProfile({ // Set formData only if profileData is not null
                    email: profileData.email,
                    name: profileData.name,
                    designation: profileData.designation,
                    company: profileData.company,
                    college: profileData.college,
                    cgpa: profileData.cgpa,
                    leetcode: profileData.leetcode,
                    github: profileData.links[0].link,
                    twitter: profileData.links[2].link,
                    linkedin: profileData.links[1].link
                });
            }
        };
        fetchData();
    }, []);


    const handleSubmit = async () => {
        // Handle form submission here

        setUploading(1);

        const userObject = {
            name: userProfile.name,
            email: localStorage.getItem("email"),
            designation: userProfile.designation,
            company: userProfile.company,
            cgpa: userProfile.cgpa,
            college: userProfile.college,
            avatar: userProfile.avatar,
            leetcode: userProfile.leetcode,
            links: [
                {
                    "name": "github",
                    "tag": "personal",
                    "link": userProfile.github,
                },
                {
                    "name": "linkedin",
                    "tag": "personal",
                    "link": userProfile.linkedin,
                },
                {
                    "name": "twitter",
                    "tag": "personal",
                    "link": userProfile.twitter,
                }
            ]
        };
        let postResponse = await postRequest(process.env.REACT_APP_URL + "/user/updateUserInfo", userObject);
        setUploading(0);
        if (postResponse.status === "200") {
            localStorage.setItem("email", userProfile.email);
            localStorage.setItem("profileObject",JSON.stringify(userObject));
        }
        handleClose(); // Close the dialog
    };

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Container maxWidth="md" sx={{ mt: 5 }}>
            {uploading === 1 && <PopUp open={true} handleClose={handleClose} popUpTitle={"Updating"} popUpMsg={"Info is Updating"} popUpColor={"#008080"} />}
            <PopupForm open={open} onClose={handleClose} formData={userProfile} handleChange={handleChange} handleSubmit={handleSubmit}/>
            {/* Profile Card */}
            <Box
                sx={{
                    backgroundColor: '#fff',
                    padding: '30px',
                    borderRadius: '12px',
                    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.1)',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {/* Avatar and Name */}
                <Avatar
                    alt={userProfile.name}
                    src={userProfile.avatar}
                    sx={{ width: 120, height: 120, mb: 3, border: '3px solid #008080' }} // Border around avatar
                />
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#008080' }}>
                    {userProfile.name}
                </Typography>

                {/* Social Icons */}
                <Box sx={{ mt: 2, mb: 4 }}>
                    <IconButton href={`${userProfile.linkedin}`} target="_blank">
                    <LinkedInIcon sx={{ fontSize: 30, color: '#0077B5' }} />
                    </IconButton>
                    <IconButton href={`${userProfile.twitter}`} target="_blank">
                    <TwitterIcon sx={{ fontSize: 30, color: '#1DA1F2' }} />
                    </IconButton>
                    <IconButton href={`${userProfile.github}`} target="_blank">
                    <GitHubIcon sx={{ fontSize: 30, color: '#333' }} />
                    </IconButton>
                </Box>

                {/* Profile Details */}
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} md={6}>
                        <Typography variant="body1" align="left" sx={{ fontWeight: 'bold' }}>
                            College:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="body1" align="left">
                            {userProfile.college}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography variant="body1" align="left" sx={{ fontWeight: 'bold' }}>
                            Company:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="body1" align="left">
                            {userProfile.company}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography variant="body1" align="left" sx={{ fontWeight: 'bold' }}>
                            Designation:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="body1" align="left">
                            {userProfile.designation}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography variant="body1" align="left" sx={{ fontWeight: 'bold' }}>
                            CGPA:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="body1" align="left">
                            {userProfile.cgpa}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography variant="body1" align="left" sx={{ fontWeight: 'bold' }}>
                            LeetCode:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="body1" align="left">
                            {userProfile.leetcode}
                        </Typography>
                    </Grid>
                </Grid>

                {/* Edit Profile Button */}
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: '#008080',
                        color: '#fff',
                        mt: 4,
                        borderRadius: '20px',
                        padding: '10px 25px',
                        '&:hover': { backgroundColor: '#006666' },
                    }}
                    onClick={handleOpen}
                >
                    Edit Profile
                </Button>
            </Box>
        </Container>
    );
};

export default ProfilePage;
