import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, Card, CardContent, IconButton, Box } from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';
import { getRequest } from "../../Fetch/request";
import Loader from "../Loader/Loader";

const NotesPage = () => {
    const [notes, setNotes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    // Array of dynamic colors for cards
    const colors = ['#FDCB6E', '#FF7675', '#55EFC4', '#74B9FF', '#00CEC9', '#FAB1A0', '#A29BFE', '#E17055'];

    useEffect(() => {
        const fetchNotes = async () => {
            try {
                const email = localStorage.getItem('email');
                const response = await getRequest(`${process.env.REACT_APP_URL}/blog/getAllBlogs?email=${email}`);
                const blogs = response?.response || [];

                const formattedNotes = blogs.map((blog, index) => ({
                    id: blog.id,
                    title: blog.title,
                    date: new Date(blog.createdAt).toLocaleDateString(),
                    color: colors[index % colors.length], // Assign colors dynamically in a loop
                }));

                setNotes(formattedNotes);
            } catch (error) {
                console.error("Error fetching notes:", error);
                setError("Failed to load notes.");
            } finally {
                setIsLoading(false);
            }
        };

        fetchNotes();
    }, []);

    if (isLoading) {
        return <Loader />;
    }

    if (error) {
        return (
            <Container maxWidth="lg" sx={{ textAlign: 'center', marginTop: '30px' }}>
                <Typography variant="h4" sx={{ color: 'red' }}>
                    {error}
                </Typography>
            </Container>
        );
    }

    return (
        <Container maxWidth="lg" sx={{ marginTop: '30px', overflowY: 'auto', maxHeight: 'calc(100vh - 100px)' }}>
            <Typography variant="h3" sx={{ fontWeight: 'bold', marginBottom: '40px', textAlign: 'center' }}>
                Notes
            </Typography>

            <Grid container spacing={3}>
                {notes.map((note) => (
                    <Grid item xs={12} sm={6} md={4} key={note.id}>
                        <Card
                            sx={{
                                backgroundColor: note.color,
                                borderRadius: '15px',
                                height: '180px', // Reduced height for a more compact view
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                position: 'relative',
                                padding: '20px',
                                transition: 'transform 0.3s ease-in-out',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                },
                            }}
                            onClick={() => {
                                // Redirect to the preview page with the query parameters
                                window.location.href = `/write?preview=true&id=${note.id}`;
                            }}
                        >
                            {/* Note Content */}
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant="h6"
                                    component="div"
                                    sx={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        textAlign: 'left',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {note.title}
                                </Typography>
                            </CardContent>

                            {/* Bottom Section with Date and Preview Icon */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '10px',
                                }}
                            >
                                {/* Date */}
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontWeight: 'bold',
                                        color: '#333', // Dark color for the date text
                                    }}
                                >
                                    {note.date}
                                </Typography>

                                {/* Preview Icon */}
                                <IconButton
                                    sx={{
                                        padding: 0, // Remove extra padding for seamless integration
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevent triggering the card's onClick
                                        window.location.href = `/rewrite?preview=true&id=${note.id}`;
                                    }}
                                >
                                    <PreviewIcon sx={{ color: '#333', fontSize: '1.5rem' }} />
                                </IconButton>
                            </Box>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default NotesPage;
