import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Box, Button, Card, Container, Typography, Grid, MenuItem, Select, Divider, IconButton
} from '@mui/material';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend } from 'chart.js';
import RefreshIcon from '@mui/icons-material/Refresh';
import {getRequest} from "../../Fetch/request";
import Loader from '../Loader/Loader';

// Register Chart.js components
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

const topicsDSA = [
    'ARRAY',
    'STRING',
    'LINKED_LIST',
    'HASH_TABLE',
    'DYNAMIC_PROGRAMMING',
    'STACK',
    'QUEUE',
    'GREEDY',
    'MATH',
    'SORTING',
    'BINARY_SEARCH',
    'GRAPH',
    'TREE',
    'TRIE',
    'BIT_MANIPULATION',
    'RECURSION',
    'GAME_THEORY',
    'DESIGN'
];
const topicsCore = ['Operating_Systems', 'Database_Systems', 'Computer_Networks'];

const ImproveWeakAreas = () => {

    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);

    const sampleTopicData = {
        "totalAttempt": 0,
        "totalCorrect": 0,
        "totalWrong": 0,
        "masteryRating": 0.0,
        "correctSubmissionCountLastFourWeeks": [],
        "wrongSubmissionCountLastFourWeeks": [],
        "referenceMaterial": []
    };

    const navigate = useNavigate();
    const [topicData, setTopicData] = useState(sampleTopicData);
    const [selectedSubject, setSelectedSubject] = useState("DSA");
    const [selectedTopic, setSelectedTopic] = useState("ARRAY");

    const topicList = selectedSubject === 'DSA' ? topicsDSA : topicsCore;

    const performanceGraphData = {
        labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
        datasets: [
            {
                label: 'Correct Answers',
                data: topicData.correctSubmissionCountLastFourWeeks,
                borderColor: '#008080',
                backgroundColor: 'rgba(74, 144, 226, 0.2)',
                tension: 0.4,
            },
            {
                label: 'Wrong Answers',
                data: topicData.wrongSubmissionCountLastFourWeeks,
                borderColor: '#D0021B',
                backgroundColor: 'rgba(208, 2, 27, 0.2)',
                tension: 0.4,
                borderWidth: 4, // thicker line
                order: 2
            },
        ],
    };

    useEffect(() => {
        if( location !== null && location.state !== null && location.state.topic !== null && location.state.type !== null){
            setSelectedTopic(location.state.topic);
            setSelectedSubject(location.state.type);
        }
    }, []);

    const refreshPage = () => {
        const fetchData = async () => {
            setIsLoading(true); // Start loading
            try {
                const profile = JSON.parse(localStorage.getItem("profileObject"));
                const email = profile?.email;

                const url = `${process.env.REACT_APP_URL}/api/topics?email=${email}&topicId=${selectedTopic}&type=${selectedSubject}`;

                const getResponse = await getRequest(url);

                if (getResponse?.status === "200" && getResponse.response) {
                    setTopicData(getResponse.response);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setIsLoading(false); // End loading
            }
        };
        fetchData();
    };


    return (
        <Container
            maxWidth="lg"
            sx={{
                marginTop: '30px',
                padding: '20px',
                backgroundColor: '#f5f5f5',
                borderRadius: '15px',
                boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.05)',
            }}
        >
            <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#333', marginBottom: '20px', textAlign: 'center' }}>
                Improve Weak Areas
            </Typography>

            {/* Subject and Topic Selection */}
            <Grid container spacing={2} sx={{ marginBottom: '30px', alignItems: 'center' }}>
                <Grid item xs={5}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#333' }}>Select Subject:</Typography>
                    <Select
                        fullWidth
                        value={selectedSubject}
                        onChange={(e) => {
                            setSelectedSubject(e.target.value)
                            setSelectedTopic(e.target.value === 'DSA' ? 'ARRAY' : 'Operating_Systems')}}
                        sx={{ backgroundColor: '#fff', borderRadius: '8px' }}
                    >
                        <MenuItem value="DSA">DSA</MenuItem>
                        <MenuItem value="CORE">Core</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={5}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#333' }}>Select Topic:</Typography>
                    <Select
                        fullWidth
                        value={selectedTopic}
                        onChange={(e) => setSelectedTopic(e.target.value)}
                        sx={{ backgroundColor: '#fff', borderRadius: '8px' }}
                    >
                        {topicList.map((topic, index) => (
                            <MenuItem key={index} value={topic}>
                                {topic}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>

                {/* Refresh Button centered between dropdowns */}
                <Grid item xs={2} display="flex" justifyContent="center" alignSelf="center">
                    <IconButton
                        color="primary"
                        onClick={refreshPage}
                        sx={{
                            backgroundColor: '#008080',
                            color: '#fff',
                            marginTop:'10%',
                            '&:hover': { backgroundColor: '#357ABD' }
                        }}
                    >
                        <RefreshIcon />
                    </IconButton>
                </Grid>
            </Grid>

            {isLoading ? (<Loader />) : (
            <>
            <Card sx={{ boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.1)', borderRadius: '15px', padding: '20px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#008080', marginBottom: '10px' }}>
                            Past Performance - {selectedTopic}
                        </Typography>
                        <Divider sx={{ marginBottom: '20px' }} />
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography variant="body1" sx={{ color: '#008080', fontWeight: 'bold' }}>Attempted:</Typography>
                                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>{topicData.totalAttempt}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body1" sx={{ color: '#008080', fontWeight: 'bold' }}>Correct:</Typography>
                                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>{topicData.totalCorrect}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body1" sx={{ color: '#D0021B', fontWeight: 'bold' }}>Wrong:</Typography>
                                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>{topicData.totalWrong}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body1" sx={{ color: '#F5A623', fontWeight: 'bold' }}>Rating:</Typography>
                                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>{topicData.masteryRating} / 2400</Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#008080', marginBottom: '10px' }}>
                            Target Work & Reference Material
                        </Typography>
                        <Divider sx={{ marginBottom: '20px' }} />
                        {topicData.referenceMaterial.map((material, index) => (
                            <Typography
                                key={index}
                                variant="body2"
                                sx={{ color: '#008080', cursor: 'pointer', textDecoration: 'underline', '&:hover': { color: '#357ABD' } }}
                                onClick={() => navigate(material.link)}
                            >
                                {material.title}
                            </Typography>
                        ))}
                        <Divider sx={{ margin: '20px 0' }} />
                        <Typography variant="body2" sx={{ color: '#555', fontWeight: 'bold' }}>Performance Over Time:</Typography>
                        <Line data={performanceGraphData} options={{ responsive: true }} height={120} />
                    </Grid>
                </Grid>
            </Card>
            </>)}

            <Box display="flex" justifyContent="center" sx={{ marginTop: '30px' }}>
                <Button
                    variant="contained"
                    sx={{ backgroundColor: '#008080', color: '#fff', padding: '10px 20px', borderRadius: '20px', fontWeight: 'bold', '&:hover': { backgroundColor: '#357ABD' } }}
                    onClick={() => navigate('/')}
                >
                    Back to Dashboard
                </Button>
            </Box>
        </Container>
    );
};

export default ImproveWeakAreas;
