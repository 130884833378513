import React from "react";
import {useState, useEffect} from "react";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from '@mui/icons-material/Cancel';
import Button from "@mui/material/Button";
import WriteComponent from "./WriteComponent";
import PublishIcon from '@mui/icons-material/Publish';
import PreviewIcon from '@mui/icons-material/Preview';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import Blog from "../Blog/Blog";
import {getRequest, postRequest} from "../../Fetch/request";
import PopUp from "../Popup/PopUp";
import { v4 as uuidv4 } from 'uuid';
import { useLocation } from 'react-router-dom';
import {uploadFileToS3} from "../../Utils/UploadToS3";
import generateS3Url from "../../Utils/GenerateS3Url";
import EditIcon from '@mui/icons-material/Edit';
import './WriteStyle.css'
import Loader from "../Loader/Loader";

const ReWrite = ({setTheId,getTheId,id}) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [popUpTitle, setPopUpTitle] = React.useState("");
    const [popUpMsg , setPopUpMsg] = React.useState("");
    const [popUpColor , setPopUpColor] = React.useState("red");

    const setPopProps = (title , msg, color) =>{
        setPopUpMsg(msg);
        setPopUpTitle(title);
        setPopUpColor(color);
    }

    const [x,setX] = React.useState(0);

    const updateParams = () => {
        const newParams = {
            id:uuidv4(),
        };

        // Get the current pathname
        const currentPathname = window.location.pathname;

        // Construct the new URL with updated parameters
        const newURL = `${currentPathname}?preview=false&id=${newParams.id}`;

        // Update the URL
        window.history.pushState({}, '', newURL);

        // Force a page reload to reflect the new URL
        window.location.reload();
    };

    const location = useLocation();
    const urlSearchParams = new URLSearchParams(location.search);
    const idValue = urlSearchParams.get('id');
    const previewValue = urlSearchParams.get("preview");
    setTheId(idValue);

    const [articleTitle, setArticleTitle] = useState("");
    const [articleComponent, setArticleComponent] = useState([]);
    const [lengthOfArticle, setLengthOfArticle] = useState(1000);
    const [textOfArticle, setTextOfArticle] = useState(new Array(1001).fill(""));
    const [imageOfArticle, setImageOfArticle] = useState(new Array(1001).fill(""));
    const [fileImageOfArticle, setFileImageOfArticle] = useState(new Array(1001).fill(""));
    const [urlImageOfArticle, setUrlImageOfArticle] = useState(new Array(1001).fill(""));
    const [textAreaActiveOfArticle, setTextAreaActiveOfArticle] = useState(new Array(1001).fill(true));

    const [loader,setLoader] = useState(0);
    const [uploading,setUploading] = useState(0);

    const [preview, setPreview] = useState(previewValue == "false"?false:true);

    const handleTitleChange = (title) => {
        setArticleTitle(title);
    };

    const addComponent = () => {
        textAreaActiveOfArticle[parseInt(lengthOfArticle)] = true;
        setTextAreaActiveOfArticle(textAreaActiveOfArticle);
        setArticleComponent([
            ...articleComponent,
            <WriteComponent
                keyIndex={parseInt(lengthOfArticle)}
                setTextOfArticle={setTextOfArticle}
                textOfArticle={textOfArticle}
                setImageOfArticle={setImageOfArticle}
                imageOfArticle={imageOfArticle}
                textAreaActiveOfArticle={textAreaActiveOfArticle}
                setTextAreaActiveOfArticle={setTextAreaActiveOfArticle}
                addComponent={addComponent}
                urlImageOfArticle={urlImageOfArticle}
                fileImageOfArticle={fileImageOfArticle}
                setFileImageOfArticle={setFileImageOfArticle}
            />,
        ]);
        setLengthOfArticle(lengthOfArticle - 1);
    };

    const removeArticleComponent = (keyIndex) => {
        let newArticleComponent = [];
        newArticleComponent = articleComponent.filter((x) => {
            if (x.props.keyIndex !== keyIndex) {
                return x;
            }
        });
        setArticleComponent(newArticleComponent);
    };

    const publishArticle = async () => {
        if( articleTitle === "" ){
            setPopProps("Alert", "Title is empty", "red");
            handleOpen();
            return;
        }

        setUploading(1);

        let contentArticlePromises = articleComponent.map(async component => {
            if( component.props.urlImageOfArticle[component.props.keyIndex] === "" &&
                component.props.textOfArticle[component.props.keyIndex] === "")
            {
                component.props.urlImageOfArticle[component.props.keyIndex] = await generateS3Url();
                setUrlImageOfArticle(component.props.urlImageOfArticle);
            }
            return {
                "text": component.props.textOfArticle[component.props.keyIndex],
                "imageUrl":component.props.urlImageOfArticle[component.props.keyIndex],
                "textAreaActive": component.props.textAreaActiveOfArticle[component.props.keyIndex]
            }
        });



        let contentArticle = await Promise.all(contentArticlePromises);
        let articleObject = {
            id: getTheId(),
            title:articleTitle,
            author: localStorage.getItem('email'),
            createdAt:Date.now(),
            content: contentArticle,
            upVote: 0,
            downVote: 0
        };

        let postResponse = await postRequest(process.env.REACT_APP_URL + "/blog/uploadBlog", articleObject);

        setUploading(0);
        if( postResponse != null && postResponse.status === "200" ){
            const contentUrls = postResponse["response"]["content"];
            for (const component of articleComponent) {
                const index = articleComponent.indexOf(component);
                let image = component.props.fileImageOfArticle[component.props.keyIndex];
                if (image instanceof File && contentUrls[index].text === "" ) {
                    await uploadFileToS3(contentUrls[index].imageUrl, image);
                }
            }

            if( postResponse.hasOwnProperty('response') && postResponse["response"].hasOwnProperty("id") ){
                setTheId(postResponse["response"]["id"]);
                setPopProps("Hurray", postResponse.message, "green");
                handleOpen();
            }
        }else{
            setPopProps("Alert", postResponse.message, "red");   // setPopProps("Alert" , "email format is not correct");
            handleOpen();
        }
    }

    useEffect(()=>{
        const fetchData = async () => {
            try {
                let sample_articleComponent = [];
                setLoader(1);
                let getResponse = await getRequest(process.env.REACT_APP_URL + '/blog/getBlog?id=' + idValue);

                if( getResponse != null && getResponse["response"].length === 0 ){
                    setArticleTitle("");
                    setArticleComponent([]);
                    setLengthOfArticle(1000);
                    setTextOfArticle(new Array(1001).fill(""));
                    setImageOfArticle(new Array(1001).fill(""));
                    setFileImageOfArticle(new Array(1001).fill(""));
                    setUrlImageOfArticle(new Array(1001).fill(""));
                    setTextAreaActiveOfArticle(new Array(1001).fill(true));
                    setPreview(false);
                }

                if(getResponse != null && getResponse["response"].length > 0 ){
                    let getObject = getResponse["response"][0];
                  setArticleTitle(getObject.title);
                  let contentObject = getObject["content"];
                  let sample_lengthOfArticle = 1000;
                  let sample_textOfArticle = new Array(1001).fill("");
                  let sample_imageOfArticle = new Array(1001).fill("");
                  let sample_urlImageOfArticle = new Array(1001).fill("");
                  let sample_textAreaActiveOfArticle = new Array(1001).fill(true);
                  for(const element of contentObject) {
                      sample_textOfArticle[parseInt(sample_lengthOfArticle)] = element.text;
                      sample_imageOfArticle[parseInt(sample_lengthOfArticle)] = element.imageUrl.split("?")[0];
                      sample_urlImageOfArticle[parseInt(sample_lengthOfArticle)] = element.imageUrl;
                      sample_textAreaActiveOfArticle[parseInt(sample_lengthOfArticle)] = element.textAreaActive;

                      setTextOfArticle(sample_textOfArticle);
                      setImageOfArticle(sample_imageOfArticle);
                      setUrlImageOfArticle(sample_urlImageOfArticle);
                      setTextAreaActiveOfArticle(sample_textAreaActiveOfArticle);
                      sample_articleComponent.push(
                          <WriteComponent
                              keyIndex={parseInt(sample_lengthOfArticle)}
                              setTextOfArticle={setTextOfArticle}
                              textOfArticle={sample_textOfArticle}
                              setImageOfArticle={setImageOfArticle}
                              imageOfArticle={sample_imageOfArticle}
                              textAreaActiveOfArticle={sample_textAreaActiveOfArticle}
                              setTextAreaActiveOfArticle={setTextAreaActiveOfArticle}
                              addComponent={addComponent}
                              urlImageOfArticle={sample_urlImageOfArticle}
                              fileImageOfArticle={fileImageOfArticle}
                              setFileImageOfArticle={setFileImageOfArticle}
                          />,
                      );
                      setLengthOfArticle(parseInt(sample_lengthOfArticle) - 1);
                      sample_lengthOfArticle--;
                  }
                    setArticleComponent(sample_articleComponent);
                }

                setLoader(0);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        // Call the fetchData function when the component mounts
        fetchData();
    },[id])

    return (
        <div
            id="qw"
            style={{
                width: "100%",
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "2%",
                textAlign: "center",
                padding: "1%",
                paddingLeft: "5%",
                paddingRight: "5%",
            }}
        >
            <PopUp open={open} handleClose={handleClose} popUpTitle={popUpTitle} popUpMsg={popUpMsg} popUpColor={popUpColor} />

            <div style={{
                position: "fixed",
                right: "0",
                bottom:"0",
                zIndex: 1,
                display:"flex",
            }}>
                {!preview&&<Button
                    component="label"
                    variant="contained"
                    startIcon={<AddIcon/>}
                    onClick={addComponent}
                    style={{
                        backgroundColor:"#008080",
                        color: "white",
                        border: "none",
                        borderRadius:"0px",
                    }}
                >
                    Add
                </Button>}
                <Button
                    component="label"
                    variant="contained"
                    startIcon={ !preview?  <PreviewIcon/>: <EditIcon/>}
                    style={{
                        backgroundColor:"#008080",
                        color: "white",
                        border: "none",
                        borderRadius:"0px"
                    }}
                    onClick={()=>{setPreview(!preview)}}
                >
                    {!preview? "PREVIEW":"EDIT"}
                </Button>

            </div>


            <div style={{
                position: "fixed",
                left: "0",
                bottom:"0",
                zIndex: 1,
                display:"flex",
            }}>
                <Button
                    component="label"
                    variant="contained"
                    startIcon={<CreateNewFolderIcon/>}
                    style={{
                        backgroundColor:"#008080",
                        color: "white",
                        border: "none",
                        borderRadius:"0px"
                    }}
                    onClick={()=>{ updateParams()}}
                >
                    New
                </Button>

                {!preview && <Button
                    component="label"
                    variant="contained"
                    startIcon={<PublishIcon/>}
                    style={{
                        backgroundColor:"#008080",
                        color: "white",
                        border: "none",
                        borderRadius:"0px"
                    }}
                    onClick={publishArticle}
                >
                    Upload
                </Button>}
            </div>

            {uploading === 1 && <PopUp open={true} handleClose={handleClose} popUpTitle={"Uploading"} popUpMsg={"Notes are Uploading"} popUpColor={"pink"} />}
            {loader === 1 && <Loader/>}

            {preview && loader === 0 && (
                    <Blog articleTitle={articleTitle} articleComponent={
                        articleComponent.map(component=>{
                            return {
                                "text" : component.props.textOfArticle[component.props.keyIndex],
                                "image": component.props.imageOfArticle[component.props.keyIndex]
                            }
                        })
                    }/>
                )
            }

            {!preview && loader === 0 &&  (
                <>
                    <div>
                        <input
                            type="text"
                            placeholder="Title"
                            style={{
                                width: "80%",
                                fontSize: "2em",
                                textAlign: "center",
                                border: "none",
                                marginBottom: "1%",
                                backgroundColor:"#eee",
                                outline:"none"
                            }}
                            value={articleTitle}
                            onChange={(e) => handleTitleChange(e.target.value)}
                        />
                    </div>


                    {articleComponent.map((component, index) => (
                        <div className="component">
                            <div
                                style={{
                                    right:"0%",
                                    center:"0%",
                                    display: "flex",
                                    position:"absolute",
                                    zIndex:"1000",
                                    color:"red"
                                }}
                            >
                                <div style={{borderRadius: "50%"}}>
                                    <CancelIcon
                                        style={{cursor: "pointer"}}
                                        onClick={() => {
                                            removeArticleComponent(parseInt(component.props.keyIndex));
                                        }}
                                    />
                                </div>
                            </div>
                            {component}
                        </div>

                    ))}




                </>
            )}
        </div>
    );
};

export default ReWrite;
