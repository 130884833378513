import React from "react";
import { useState, useEffect } from "react";
import ImageUpload from "../ImageUpload/ImageUpload";
import AttachmentIcon from "@mui/icons-material/Attachment";
import TextArea from "../TextArea/TextArea";
import {styled} from "@mui/material/styles";
import Button from "@mui/material/Button";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const WriteComponent = ({
  keyIndex,
  setTextOfArticle,
  textOfArticle,
  setImageOfArticle,
  imageOfArticle,
  textAreaActiveOfArticle,
                              setTextAreaActiveOfArticle,
    addComponent,
                              urlImageOfArticle,
                              fileImageOfArticle,
                              setFileImageOfArticle
}) => {

  const [value,setValue]= useState("");

  const [isTextAreaActive, setIsTextAreaActive] = useState(
    textAreaActiveOfArticle[parseInt(keyIndex)]
  );

  const [text, setText] = useState(
    textOfArticle[parseInt(keyIndex)]
  );

  const [selectedImage, setSelectedImage] = useState(
    imageOfArticle[parseInt(keyIndex)]
  );

  const handleInputChange = (e) => {
    setText(e.target.value);
    setValue(e.target.value);
    textOfArticle[parseInt(keyIndex)] = e.target.value;
    setTextOfArticle(textOfArticle);
  };

   const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
          fileImageOfArticle[parseInt((keyIndex))]= file;
          setFileImageOfArticle(fileImageOfArticle);
        setSelectedImage(reader.result);
        textAreaActiveOfArticle[parseInt(keyIndex)] = !textAreaActiveOfArticle[parseInt(keyIndex)];
          setTextAreaActiveOfArticle(textAreaActiveOfArticle);
        setIsTextAreaActive(textAreaActiveOfArticle[parseInt(keyIndex)]);
        imageOfArticle[parseInt(keyIndex)] = reader.result;
        setImageOfArticle(imageOfArticle);
      };
      reader.readAsDataURL(file);
    }

  };

  const removeImage = () => {
      textAreaActiveOfArticle[parseInt(keyIndex)] = !textAreaActiveOfArticle[parseInt(keyIndex)];
        setTextAreaActiveOfArticle(textAreaActiveOfArticle);
      setIsTextAreaActive(textAreaActiveOfArticle[parseInt(keyIndex)]);
    imageOfArticle[parseInt(keyIndex)] = "";
    setImageOfArticle(imageOfArticle);
    setSelectedImage(imageOfArticle[parseInt(keyIndex)]);
  };

  useEffect(() => {
    setText(textOfArticle[parseInt(keyIndex)]);
    setSelectedImage(imageOfArticle[parseInt(keyIndex)]);
    setIsTextAreaActive(textAreaActiveOfArticle[parseInt(keyIndex)]);
  }, [textOfArticle[parseInt(keyIndex)] , imageOfArticle[parseInt(keyIndex)] , textAreaActiveOfArticle[parseInt(keyIndex)]]);

  return (
    <div
      style={{
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "2%",
        marginBottom: "2%",
        textAlign: "center",
        padding: "1%",
      }}
    >
      <div style={{ display: "flex", width: "100%" }}>
        {isTextAreaActive && (
            <>
              <Button
                  component="label"
                  startIcon={<AttachmentIcon />}
                  style={{height:"5%",color:"#008080"}}
              >
                <VisuallyHiddenInput type="file" onChange={handleImageChange} />
              </Button>
          <div
            className={`custom-textarea ${isTextAreaActive ? "active" : ""}`}
            style={{ width: "100%" }}
          >
            <TextArea
              handleInputChange={handleInputChange}
              value={textOfArticle[parseInt(keyIndex)]}
              keyIndex={keyIndex}
            />
          </div>
            </>
        )}
        {!isTextAreaActive && (
          <div style={{ width: "100%" }}>
            <ImageUpload
              handleImageChange={handleImageChange}
              removeImage={removeImage}
              image={imageOfArticle[parseInt(keyIndex)]}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default WriteComponent;
