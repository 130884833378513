import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';

const PopupForm = ({ open, onClose, formData, handleChange, handleSubmit }) => {
  return (
      <Dialog
          open={open}
          onClose={onClose}
          PaperProps={{
            style: {
              borderRadius: 15,
              padding: '20px',
              maxWidth: '600px',
            },
          }}
      >
        <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold', color: '#008080' }}>
          Update Your Profile
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 2, textAlign: 'center' }}>
            Please fill out your details to update your profile.
          </Typography>
          <Grid container spacing={2}>
            {/* Row 1 */}
            <Grid item xs={12}>
              <TextField
                  label="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{ style: { fontWeight: 500 } }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 8,
                    },
                  }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  label="Designation"
                  name="designation"
                  value={formData.designation}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{ style: { fontWeight: 500 } }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 8,
                    },
                  }}
              />
            </Grid>

            {/* Row 2 */}
            <Grid item xs={12} md={6}>
              <TextField
                  label="Company"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{ style: { fontWeight: 500 } }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 8,
                    },
                  }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                  label="College"
                  name="college"
                  value={formData.college}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{ style: { fontWeight: 500 } }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 8,
                    },
                  }}
              />
            </Grid>

            {/* Row 3 */}
            <Grid item xs={12} md={6}>
              <TextField
                  label="CGPA"
                  name="cgpa"
                  value={formData.cgpa}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{ style: { fontWeight: 500 } }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 8,
                    },
                  }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                  label="Leetcode Username"
                  name="leetcode"
                  value={formData.leetcode}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{ style: { fontWeight: 500 } }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 8,
                    },
                  }}
              />
            </Grid>

            {/* Row 4 */}
            <Grid item xs={12} md={6}>
              <TextField
                  label="GitHub Link"
                  name="github"
                  value={formData.github}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{ style: { fontWeight: 500 } }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 8,
                    },
                  }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                  label="Twitter Link"
                  name="twitter"
                  value={formData.twitter}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{ style: { fontWeight: 500 } }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 8,
                    },
                  }}
              />
            </Grid>

            {/* Row 5 */}
            <Grid item xs={12}>
              <TextField
                  label="LinkedIn Link"
                  name="linkedin"
                  value={formData.linkedin}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{ style: { fontWeight: 500 } }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 8,
                    },
                  }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', mt: 2 }}>
          <Button
              onClick={onClose}
              variant="outlined"
              color="error"
              sx={{
                borderRadius: 8,
                padding: '8px 20px',
              }}
          >
            Cancel
          </Button>
          <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              sx={{
                borderRadius: 8,
                padding: '8px 20px',
                backgroundColor: '#008080',
                '&:hover': {
                  backgroundColor: '#006666',
                },
              }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
  );
};

export default PopupForm;
